$content-padding--large: 0;
$content-title-width: 100%;
$content-title-font: 500 #{rem-calc(22)} / rem-calc(24) $font-primary;
$content-title-font--large: 500 #{rem-calc(36)} / rem-calc(38) $font-primary-medium;
$content-title-margin: rem-calc(0 0 20);
$content-title-max-width: rem-calc(320);
$content-title-max-width--large: 100%;
$content-centered-padding: rem-calc(37 25 0);
$content-centered-secondary-background: color(light);
$content-centered-inner-max-width--large: rem-calc(435);
$content-secondary-background: color(global-background);
$content-main-top-border-bottom: 0;
$content-main-top-margin: rem-calc(0 0 20);
$content-main-top-margin--large: rem-calc(0 0 50);
$content-section-padding: rem-calc(0 15 25);
$content-section-padding--large: rem-calc(1 20);
$content-title-margin--large: rem-calc(0 0 16);
$content-check-order-width--large: rem-calc(370);
$content-secondary-padding--large: rem-calc(0 0 56);
$content-description-color: color(organism-background);
$content-section-item-margin: rem-calc(30 0 20);
$content-sidebar-columns--large: 3 of 12;
$content-container-columns--large: 9 of 12;

@import "@lora/04-layout/content";
