$stacked-links-margin: rem-calc(25 15);
$stacked-links-item-first-child-border-top: 1px solid color(border);
$stacked-links-link-font: 300 #{rem-calc(12)} / 1 $font-secondary;
$stacked-links-link-color: color(organism-background);
$stacked-links-link-padding: rem-calc(17 0 17 20);
$stacked-links-link-padding--large: $stacked-links-link-padding;
$stacked-links-link-hover-color: color(primary);
$stacked-links-link-hover-font: $stacked-links-link-font;
$stacked-links-link-icon: chevron2-right;
$stacked-links-link-icon-margin: rem-calc(0 24 0 0);
$stacked-links-link-icon-width: rem-calc(6);
$stacked-links-link-icon-height: rem-calc(10);
$stacked-links-link-active-color: color(primary);
$stacked-links-link-active-font: 500 #{rem-calc(12)} / 1 $font-primary-medium;
$stacked-links-link-active-margin: rem-calc(0 0 0 10);
$stacked-links-link-logout-background: color(primary);
$stacked-links-link-logout-color: color(secondary);
$stacked-links-link-logout-font: 500 #{rem-calc(12)} $font-primary;
$stacked-links-link-logout-text-transform: uppercase;
$stacked-links-link-logout-letter-spacing: rem-calc(0.8);
$stacked-links-link-logout-margin: rem-calc(25 0 10);
$stacked-links-link-logout-icon-margin--large: rem-calc(0 20 0 0);
$stacked-links-link-logout-hover-background: color(secondary);
$stacked-links-link-logout-hover-color: color(text);
$stacked-links-link-logout-border: 1px solid transparent;
$stacked-links-link-logout-hover-border: 1px solid color(primary-active);
$stacked-links-border: 1px solid color(border);
$stacked-links-item-last-child-border-bottom: none;

@import "@lora/05-components/stacked-links";
