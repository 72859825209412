$search-breadcrumbs-separator: 0;
$search-tools-margin--large: 0;
$search-product-results-separator--large: none;
$search-product-results-count-text-width: 65%;
$search-panel-margin: rem-calc(0 0 40);
$search-panel-margin--large: rem-calc(0 0 40);
$search-product-results-count-font: 500 #{rem-calc(22)} / #{rem-calc(24)} $font-primary;
$search-product-results-count-font--large: 500 #{rem-calc(22)} / #{rem-calc(55)} $font-primary;
$search-results-count-inline-margin: rem-calc(10 7 9 0);

@import "@lora/04-layout/search";
