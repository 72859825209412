// Title
$content-list-title-font: 500 #{rem-calc(22)} / rem-calc(24) $font-primary;
$content-list-title-font--large: 500 #{rem-calc(36)} / rem-calc(38) $font-primary;
$content-list-title-text-align--large: left;
// Description
$content-list-description-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$content-list-description-font--large: $content-list-description-font;
$content-list-description-text-color--large: color(organism-background);
$content-list-description-text-align--large: left;
$content-list-description-text-transform--large: none;
// Article
$content-list-article-margin: rem-calc(0 0 40);
$content-list-article-margin--large: rem-calc(0 0 20);
$content-list-article-first-margin: $content-list-article-margin;
$content-list-article-first-margin--large: rem-calc(26 0 20);
$content-list-article-last-margin: rem-calc(0 0 20);
$content-list-article-padding: rem-calc(0 0 25) !default;
$content-list-article-padding--large: rem-calc(0 0 20);
$content-list-article-first-padding: rem-calc(20 0 25);
$content-list-article-first-padding--large: $content-list-article-padding--large;
$content-list-padding--large: rem-calc(0 0 50);
$content-list-article-last-padding: 0;
$content-list-article-border-top: none;
$content-list-article-last-border-bottom: none;
$content-list-article-border-bottom--large: none;

@import "@lora/05-components/content-list";
