
                            $is-app_ng_jp-enabled: true;
                            $contact-us-width--large: 75% !default;
$contact-us-label-padding: rem-calc(20) !default;
$contact-us-confirmation-section-padding: rem-calc(30) !default;
$contact-us-confirmation-section-margin: rem-calc(20) !default;
$contact-us-back-flex--large: 1 1 auto !default;
$contact-us-phone-section-icon: phone !default;
$contact-us-phone-section-icon-height: rem-calc(19) !default;
$contact-us-phone-section-icon-width: rem-calc(19) !default;
$contact-us-phone-section-icon-margin: rem-calc(0 5 0 0) !default;
$contact-us-phone-section-icon-color: color(dark) !default;
$contact-us-section-margin: rem-calc(0 0 20) !default;
$contact-us-section-padding: rem-calc(20) !default;

@mixin jplayer-layout-contact-us {
    .c-contactus {
        @include breakpoint(large) {
            width: $contact-us-width--large;
        }
    }

    .c-contact-us-back {
        @include breakpoint(large) {
            flex: $contact-us-back-flex--large;
        }
    }

    .c-contact-us__field-label {
        .c-contact-us__label-wrap & {
            padding: $contact-us-label-padding;
        }
    }

    .c-contact-us-confirmation {
        margin-bottom: $contact-us-confirmation-section-margin;
        padding: $contact-us-confirmation-section-padding;

        @include breakpoint(large) {
            margin: 0;
        }
    }

    .c-contactus__phone-section {
        .m-phone {
            @include svg-icon($contact-us-phone-section-icon, $contact-us-phone-section-icon-color, 100%, left, no-repeat);

            height: $contact-us-phone-section-icon-height;
            margin: $contact-us-phone-section-icon-margin;
            width: $contact-us-phone-section-icon-width;
        }
    }

    .c-contact-us__section {
        margin: $contact-us-section-margin;
        padding: $contact-us-section-padding;
    }
}

                        